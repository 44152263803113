import {useEffect, useState} from "react";
import {Loader} from "../Loader/Loader";
import clsx from "clsx";
import styles from "./Banners.module.scss";
import {Link} from "react-router-dom";

export function Banners() {
    let [loading, setLoading] = useState(false);
    let [banners, setBanners] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetch("/api/banners", {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                setBanners(response)
            });
        setLoading(false);
    }, []);

    if (banners.length > 0) {
        return (
            <>
                {
                    loading
                        ? <Loader/>
                        : <>
                            {
                                banners.map((item) => {
                                    return <article
                                        key={item.id}
                                        className={clsx(styles.announce, styles.news)}
                                    >
                                        <h2>{item.title}</h2>
                                        {
                                            item.image &&
                                            <img
                                                className={styles.cover}
                                                src={item.image}
                                                alt=""
                                            />
                                        }
                                        <p>{item.description}</p>
                                        {
                                            item.button.length > 0 &&
                                            <Link
                                                to={item.link}
                                                className={"btn btnPrimary btnSmall"}
                                            >
                                                {item.button}
                                            </Link>
                                        }
                                    </article>
                                })
                            }
                        </>
                }
            </>
        )
    } else {
        return null
    }
}