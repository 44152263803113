export let periodsList = [
    {
        "id": "week",
        "name": "Неделя"
    },
    {
        "id": "month",
        "name": "Месяц"
    },
    {
        "id": "3months",
        "name": "3 месяца"
    },
    {
        "id": "6months",
        "name": "6 месяцев"
    },
    {
        "id": "year",
        "name": "Год"
    },

];