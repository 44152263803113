import {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ScrollToTop} from "./js/modules/Util/ScrollToTop";

import {MyInfo} from "./js/modules/MyInfo/MyInfo";
import {MyReceipts} from "./js/modules/MyReceipts/MyReceipts";
import {MySales} from "./js/modules/MySales/MySales";
import {MyGifts} from "./js/modules/MyGifts/MyGifts";
import {useActor} from "@xstate/react";
import {AuthState} from "./js/xstate/auth.machine";
import {AccountLayout} from "./js/modules/AccountLayout/AccountLayout";
import {UploadReceipt} from "./js/modules/UploadReceipt/UploadReceipt";
import {SellerStat} from "./js/modules/SellerStat/SellerStat";
import {ShopStat} from "./js/modules/ShopStat/ShopStat";
import {DistributorStat} from "./js/modules/DistributorStat/DistributorStat";
import {Statistics} from "./js/modules/Statistics/Statistics";
import {ProducerStat} from "./js/modules/ProducerStat/ProducerStat";
import {Loader} from "./js/modules/Loader/Loader";
import {ShopPage} from "./js/modules/ShopPage/ShopPage";

/* Lazy loading of react components */
const Home = lazy(() => import("./js/Pages/Home").then(({Home}) => ({default: Home})));
const Page404 = lazy(() => import("./js/Pages/Page404").then(({Page404}) => ({default: Page404})));
const Shop = lazy(() => import("./js/Pages/Shop").then(({Shop}) => ({default: Shop})));
const Account = lazy(() => import("./js/Pages/Account").then(({Account}) => ({default: Account})));
const Feedback = lazy(() => import("./js/Pages/Feedback").then(({Feedback})=>({default: Feedback})));
const Auth = lazy(() => import("./js/Pages/Auth").then(({Auth})=>({default: Auth})));

function App() {

    let [authState, authSend] = useActor(AuthState);

    return (
        <>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Home/>
                        </Suspense>
                    }/>
                    <Route path="/shop" element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Shop/>
                        </Suspense>

                    }>
                        {/*<Route element={<Showcase/>}/>*/}
                        <Route index element={<ShopPage/>}/>
                    </Route>
                    <Route path="/feedback" element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Feedback/>
                        </Suspense>
                    }/>
                    <Route path="/auth" element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Auth/>
                        </Suspense>
                    }/>
                    <Route path="*" element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Page404/>
                        </Suspense>
                    }/>

                    <Route path={"/account"} element={
                        <Suspense fallback={<div className={"fullPageLoader"}><Loader/></div>}>
                            <Account/>
                        </Suspense>
                    }>
                        <Route element={<AccountLayout/>}>
                            <Route index element={<MyInfo/>}/>

                            /* Страница загрузки чека доступна только продавцу и владельцу точки */
                            <Route path="upload" element={
                                (authState.context.type === "seller" || authState.context.type === "owner")
                                    ? <UploadReceipt/>
                                    : <Page404/>
                            }/>

                            /* Страница со списком загруженных чеков доступна только продавцу и владельцу */
                            <Route path="receipts" element={
                                (authState.context.type === "seller" || authState.context.type === "owner")
                                    ? <MyReceipts/>
                                    : <Page404/>
                            }/>

                            /* Страница Мои продажи доступна только продавцу */
                            <Route path="sales" element={
                                authState.context.type === "seller"
                                    ? <MySales/>
                                    : <Page404/>
                            }/>

                            /* Страница приобретенных призов доступна только продавцу и владельцу точки */
                            <Route path="gifts" element={
                                (authState.context.type === "seller" || authState.context.type === "owner")
                                    ? <MyGifts/>
                                    : <Page404/>
                            }/>

                            /* Страница статистики недоступна для продавца */
                            <Route
                                path="statistics"
                                element={
                                    <>
                                        {authState.context.type === "seller"
                                            ? <Page404/>
                                            : <Statistics/>
                                        }
                                    </>

                                }
                            >

                                {
                                    authState.context.type === "owner" &&
                                    <>
                                        <Route index element={<ShopStat/>}/>
                                        <Route path={":sellerID"} element={<SellerStat/>}/>
                                    </>
                                }

                                {
                                    authState.context.type === "distributor" &&
                                    <>
                                        <Route index element={<DistributorStat/>}/>
                                        <Route path={":shopID"} element={<ShopStat/>}/>
                                        <Route path={":shopID/:sellerID"} element={<SellerStat/>}/>
                                    </>
                                }
                                {
                                    authState.context.type === "producer" &&
                                    <>
                                        <Route index element={<ProducerStat/>}/>
                                        <Route path={":distributorID"} element={<DistributorStat/>}/>
                                        <Route path={":distributorID/:shopID"} element={<ShopStat/>}/>
                                        <Route path={":distributorID/:shopID/:sellerID"} element={<SellerStat/>}/>
                                    </>
                                }

                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;
