import {assign, createMachine} from "xstate";

/*
* Эта машина управляет данными магазина.
*
* Здесь делается запрос данных, она же хранит данные в контексте:
* список товаров, список категорий, количество страниц для данной выдачи, номер текущей страницы.
*
*
* Параметры запроса хранятся и уточняются.
*
*/

function getMessageData(ctx, message) {
    return message.data
}

let defaultContext = {
    list: [],
    current_page: 1,
    pages: undefined,
    categories: [],
    errorMsg: [],

    /* Дефолтные значения фильтров */
    category: 0,
    sort: "low_first", //Другие значения "high_first", "new_first"
    available: false,
    can_buy: false,
    query: "",
}

export const shopMachine = createMachine({
        id: "shop",
        context: {...defaultContext},
        initial: "init", //получаем GET-параметр из url, чтобы отправить запрос сразу с ним

        states: {
            "init": {
                always: {
                    actions: "getParameter",
                    target: "loading",
                }
            },
            "loading": {
                invoke: {
                    id: "getData",
                    src: "getData",

                },
                on: {
                    "done": {
                        target: "success",
                        actions: ["saveData"]
                    },
                    "error": {
                        target: "failure",
                        actions: ["setError"]
                    }
                },
                entry: ["scrollTop"]
            },
            "success": {
                on: {
                    "changeFilters": {
                        actions: ["saveData"],
                        target: "loading"
                    },

                    "changeSorting": {
                        actions: ["saveSorting"],
                        target: "loading"
                    },
                    "changeCategory": {
                        actions: ["saveCategory"],
                        target: "loading"
                    },
                    "changeAvailability": {
                        actions: ["saveAvailability"],
                        target: "loading"
                    },
                    "changeCanBuy": {
                        actions: ["saveCanBuy"],
                        target: "loading"
                    },

                    "searching": {
                        actions: ["setSearchQuery"],
                        target: "loading",
                    },
                    "prevPage": {
                        actions: ["changePage"],
                        target: "loading",
                        cond: "isntFirstPage"
                    },
                    "nextPage": {
                        actions: ["changePage"],
                        target: "loading",
                        cond: "isntLastPage"
                    },

                }
            },
            "failure": {
                on: {
                    "retry": {
                        target: "loading"
                    }
                }
            },
        }
    },
    {
        actions: {
            "saveData": assign({
                "list": (ctx, message) => message.data.items ?? ctx.list,
                "current_page": (ctx, message) => message.data.current_page ?? ctx.current_page,
                "pages": (ctx, message) => message.data.pages ?? ctx.pages,
                "categories": (ctx, message) => message.data.categories ?? ctx.categories,
                "sort": (ctx, message) => message.data.sort ?? ctx.sort,
                "category": (ctx, message) => message.data.category ? Number(message.data.category) : ctx.category,
                "errorMsg": (ctx, message) => message.data.error
            }),
            "saveCategory": assign({
                "category": getMessageData
            }),
            "saveSorting": assign({
                "sort": getMessageData
            }),
            "saveAvailability": assign({
                "available": getMessageData
            }),
            "saveCanBuy": assign({
                "can_buy": getMessageData
            }),

            "resetContext": assign({
                "list": [],
                "current_page": 1,
                "categories": [],
                "errorMsg": "",
                /* Фильтры */
                "category": 0,
                "sort": "low_first",
                "available": false,
                "can_buy": false
            }),
            "setSearchQuery": assign({
                "query": (ctx, message) => message.data
            }),
            "getParameter": assign(
                {
                    "query": () => {
                        let pageURL = new URL(window.location.href);
                        let query = pageURL.searchParams.get("query");

                        return query || ""
                    }
                }
            ),
            "changePage": assign({
                "current_page": (ctx, message) => {
                    return message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
                }
            }),

            "setError": assign((ctx, message) => message.data),
            "scrollTop": () => {
                window.scrollTo(0, 0);
            }
        },
        services: {
            "getData": (ctx) => {
                return function (send) {
                    let token = localStorage.getItem('sessionID');
                    let url = new URL('/api/shop', window.location.href);

                    if (ctx.category !== defaultContext.category) {
                        url.searchParams.set("category", ctx.category)
                    }
                    if (ctx.current_page !== defaultContext.current_page) {
                        url.searchParams.set("page", ctx.current_page)
                    }
                    if (ctx.sort !== defaultContext.sort) {
                        url.searchParams.set("sort", ctx.sort)
                    }
                    if (ctx.available !== defaultContext.available) {
                        url.searchParams.set("available", ctx.available)
                    }
                    if (ctx.can_buy !== defaultContext.can_buy) {
                        url.searchParams.set("can_buy", ctx.can_buy)
                    }
                    if (ctx.query !== defaultContext.query) {
                        url.searchParams.set("query", ctx.query)
                    }

                    let headers = {
                        "Accept": "application/json",
                    };
                    if (token) {
                        headers["Authorization"] = `Bearer ${token}`;
                    }

                    fetch(url.toString(), {
                        method: "GET",
                        headers
                    })
                        .then(response => {
                            return response.json();
                        })
                        .then(response => {
                            if (response.code === 200) {
                                send({
                                    type: "done",
                                    data: response,
                                })
                            } else {
                                send({
                                    type: "error",
                                    data: response
                                })
                            }
                        })
                }
            },
        },
        "guards": {
            "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
            "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,

        }
    });

/*
export const ShopState = interpret(shopMachine, {devTools: true}).start();*/
