import {useEffect, useState} from "react";
import {useActor, useMachine} from "@xstate/react";
import {myGiftsMachine} from "../../xstate/mygifts.machine";
import styles from "./MyGifts.module.scss";
import {Bonuses} from "../Bonuses/Bonuses";
import {Banners} from "../Banners/Banners";
import {Icon} from "../SVGsprite/svgsprite";
import {Link} from "react-router-dom";
import {Loader} from "../Loader/Loader";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";
import {AuthState} from "../../xstate/auth.machine";
import clsx from "clsx";

export function MyGifts() {
    let [stateAuth] = useActor(AuthState);
    let [stateGifts, sendGifts] = useMachine(myGiftsMachine);

    useEffect(() => {
        document.title = "Мои подарки | Программа лояльности SK ZIC";
    }, []);

    return (<>
            <section className={styles.workarea}>
                <div className="titleBlock">
                    <h1>Мои подарки</h1>
                    {
                        stateAuth.context.can_upload &&
                        <Link to={"/account/upload"} className={"btn btnPrimary btnIcon"}>
                            <Icon name={"icon-add"} sizeW="24" sizeH="24"/>
                            Загрузить чек
                        </Link>
                    }
                </div>
                <div className={"statContainer"}>
                    {
                        stateGifts.matches("loading")
                            ? <Loader/>
                            : <>
                                <GiftsTable data={stateGifts.context.list}/>
                                {
                                    stateGifts.context.list.length > 0 &&
                                    <Pagination
                                        pages={stateGifts.context.pages}
                                        current_page={stateGifts.context.current_page}
                                        send={sendGifts}
                                    />
                                }
                            </>
                    }
                </div>
            </section>
            <aside className={styles.sidebar}>
                <Bonuses/>
                <Banners/>
            </aside>
        </>
    )
}

function GiftsTable(props) {

    if (props.data.length === 0) {
        return <NoData/>
    }

    return (
        <div className={"scrollbox"}>
            <table className={"statTable"}>
                <thead>
                <tr>
                    <th>Дата оформления</th>
                    <th>Фото</th>
                    <th>Наименование</th>
                    <th>Промокод</th>
                    <th>Баллы</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => {
                        return <tr key={item.promocode}>
                            <td>{item.date}</td>
                            <td>
                                <img src={item.image} alt=""/>
                            </td>
                            <td className={styles.name}>
                                {item.name}
                            </td>
                            <td>
                                <PromocodeBlock
                                    promocode={item.promocode}
                                />
                            </td>
                            <td className={styles.flow}>
                                -{new Intl.NumberFormat('ru-RU').format(item.flow)} баллов
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

function PromocodeBlock (props) {
    let [isCopied, setIsCopied] = useState(false);

    return (
        <div className={styles.promocode}>
            {
                props.promocode
            }
            <button
                className={"btn btnPrimary btnSmall"}
                type={"button"}
                onClick={
                    () => {
                        navigator.clipboard.writeText(props.promocode)
                            .then(() => {
                                setIsCopied(true);

                                let timer = setTimeout(() => {
                                    setIsCopied(false)
                                    clearTimeout(timer)
                                }, 1000)
                            })
                    }
                }
            >
                Скопировать
            </button>

            <div
                className={clsx(styles.copySuccess, {[styles.active]: isCopied})}
                aria-live={"polite"}
            >
                {
                    isCopied ? "Промокод скопирован" : ""
                }
            </div>
        </div>
    )
}