import {assign, createMachine} from "xstate";

export const myGiftsMachine = createMachine({
    id: "myGifts",
    context: {
        list: [],
        current_page: 1,
        pages: undefined,
        errorMsg: [],
    },
    initial: "loading",
    states: {
        "loading": {
            invoke: {
                id: "getData",
                src: "getData",
            },
            on: {
                "done": {
                    target: "success",
                    actions: ["saveData"]
                },
                "error": {
                    target: "failure",
                    actions: ["setError"]
                }
            },
            entry: "scrollTop",
        },
        "success": {
            on: {
                "prevPage": {
                    actions: ["changePage"],
                    target: "loading",
                    cond: "isntFirstPage"
                },
                "nextPage": {
                    actions: ["changePage"],
                    target: "loading",
                    cond: "isntLastPage"
                },
            }
        },
        "failure": {
            on: {
                "retry": {
                    target: "loading"
                }
            }
        }
    }
}, {
    actions: {
        "saveData": assign({
            "list": (ctx, message) => message.data.items,
            "current_page": (ctx, message) => message.data.current_page,
            "pages": (ctx, message) => message.data.pages
        }),
        "changePage": assign({
            "current_page": (ctx, message) => {
                return message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
            }
        }),
        "setError": assign((ctx, message) => message.data),
        "scrollTop": () => {
            /*document.getElementById("receiptsTable")?.scrollTo(0, 0);*/
            window.scrollTo(0, 0);
        }
    },
    services: {
        "getData": (ctx) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                let url = new URL('/api/personal/gifts', window.location.href);
                url.searchParams.set("page", ctx.current_page);

                fetch(url.toString(),
                    {
                        method: "GET",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${token}`
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 200) {
                            send({
                                type: "done",
                                data: response,
                            })
                        } else {
                            send({
                                type: "error",
                                data: response
                            })
                        }
                    })
            }
        }
    },
    guards: {
        "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
        "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,
    }
});