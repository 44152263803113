import {useEffect} from "react";
import {useActor, useMachine} from "@xstate/react";
import {AuthState} from "../../xstate/auth.machine";
import styles from "./MySales.module.scss";
import {Bonuses} from "../Bonuses/Bonuses";
import {Banners} from "../Banners/Banners";
import {mySalesMachine} from "../../xstate/mysales.machine";
import {ChartSelect} from "../ChartSelect/ChartSelect";
import {Pagination} from "../Pagination/Pagination";
import {Icon} from "../SVGsprite/svgsprite";
import {Link} from "react-router-dom";
import {Loader} from "../Loader/Loader";
import {ChartArea} from "../ChartArea/ChartArea";
import {periodsList} from "../../constants";
import {NoData} from "../NoData/NoData";
import clsx from "clsx";


export function MySales() {

    let [stateAuth, sendAuth] = useActor(AuthState);
    let [stateMySales, sendMySales] = useMachine(mySalesMachine);

    useEffect(() => {
        document.title = "Мои продажи | Программа лояльности SK ZIC";
    }, []);

    return (<>
        <section className={styles.workarea}>
            <div className="titleBlock">
                <h1>Мои продажи</h1>

                <Link to={"/account/upload"} className={"btn btnPrimary btnIcon"}>
                    <Icon name={"icon-add"} sizeW="24" sizeH="24"/>
                    Загрузить чек
                </Link>
            </div>

            <section className={clsx("statContainer", styles.chartArea)}>
                <div className={styles.titleBlock}>
                    <h2>График продаж</h2>

                    <div className={styles.chartControls}>
                        <ChartSelect
                            send={sendMySales}
                            type={"CHANGE_FILTER"}
                            dataName={"period"}
                            label={"Выбор периода"}
                            name={"periodSelect"}
                            defaultValue={"week"}
                            options={[...periodsList]}
                        />
                    </div>
                </div>

                {
                    stateMySales.matches({chart: "loading"})
                        ? <Loader/>
                        : <ChartArea
                            chart_data={stateMySales.context.chart_data}
                        />
                }
            </section>

            <section className={"statContainer"}>
                <h2 className="visuallyHidden">Таблица продаж</h2>

                {
                    stateMySales.context.list.length > 0
                        ? <>
                            <SalesTable
                                data={stateMySales.context.list}
                            />
                            {
                                stateMySales.context.pages > 1 &&
                                <Pagination
                                    pages={stateMySales.context.pages}
                                    current_page={stateMySales.context.current_page}
                                    send={sendMySales}
                                />
                            }
                        </>
                        : <NoData/>
                }
            </section>
        </section>
        {
            (stateAuth.context.type === "seller" || stateAuth.context.type === "owner") &&
            <aside className={styles.sidebar}>
                <Bonuses/>
                <Banners/>
            </aside>
        }
    </>)
}

function SalesTable(props) {

    if (props.data.length === 0) {
        return <NoData/>
    }

    return (
        <div className={"scrollbox"}>
            <table id={"salesTable"} className={"statTable"}>
                <thead>
                <tr>
                    <th>Дата и время продажи</th>
                    <th>Товар</th>
                    <th>Кол-во ед.</th>
                    <th>Сумма продаж</th>
                    <th>Баллов получено</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => {
                        return <tr key={item.id}>
                            <td>
                                {item.date}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {item.name}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {item.quantity}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.points)} баллов
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    )
}