import {assign, createMachine} from "xstate";

export const producerStatMachine = createMachine({
    id: "owner-stat",
    initial: "loadingShopData",
    type: "parallel",
    context: {
        period: "week", // month, 3months, 6months, year
        chart_data: [],
        distributors: [], /* список дистрибьюторов для выпадающего списка */
        current_distributor: 0, /* id дистрибьютора; ноль означает "Все" */
        current_page: 1,
        pages: undefined,
        list: [],
        errorMsg: []
    },
    states: {
        chart: {
            initial: "loading",
            states: {
                "loading": {
                    entry: "scrollTop",
                    invoke: {
                        id: "getChartData",
                        src: "getChartData"
                    },
                    on: {
                        "DONE_CHART": {
                            actions: ["saveChartData"],
                            target: "success"
                        },
                        "ERROR_CHART": {
                            target: "failure",
                            actions: ["saveError"]
                        }
                    }
                },
                "success": {
                    on: {
                        "CHANGE_FILTER": {
                            actions: ["saveChartData"],
                            target: "loading"
                        }
                    }
                },
                "failure": {}
            }
        },
        table: {
            initial: "loading",
            states: {
                "loading": {
                    invoke: {
                        id: "getTableData",
                        src: "getTableData"
                    },
                    on: {
                        "DONE_TABLE": {
                            target: "success",
                            actions: ["saveTableData"]
                        },
                        "ERROR_TABLE": {
                            target: "failure",
                            actions: ["saveError"]
                        }
                    }
                },
                "success": {
                    on: {
                        "prevPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntFirstPage"
                        },
                        "nextPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntLastPage"
                        },
                    }
                },
                "failure": {}
            }
        }
    }
}, {
    actions: {
        "saveChartData": assign({
            "period": (ctx, message) => message.data.period ?? ctx.period,
            "chart_data": (ctx, message) => message.data.data ?? ctx.chart_data,
            "distributors": (ctx, message) => message.data.distributors ?? ctx.distributors,
            "current_distributor": (ctx, message)=>message.data.distributor ?? ctx.current_distributor
        }),
        "saveTableData": assign({
            "current_page": (ctx, message)=> message.data.current_page,
            "pages": (ctx, message)=> message.data.pages,
            "list": (ctx, message)=> message.data.distributors,
        }),
        "changePage": assign({
            "current_page": (ctx, message) => {
                return message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
            }
        }),
        "scrollTop": () => {
            window.scrollTo(0, 0);
        },
        "saveError": assign({
            "errorMsg": (ctx, message)=> message.data.message
        })
    },
    services: {
        "getChartData": (ctx) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                let url = new URL("/api/personal/distributors/chart", window.location.href);

                if (ctx.period !== "week") {
                    url.searchParams.set("period", ctx.period)
                }

                fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(response => {
                        /*console.log(response.headers.get("content-type"))*/
                        return response.json();
                    })
                    .then(response => {
                        if (response.code === 200) {
                            send({
                                type: "DONE_CHART",
                                data: response
                            })
                        }
                        else{
                            send({
                                type: "ERROR_CHART",
                                data: response
                            })

                        }
                    })
            }
        },
        "getTableData": (ctx) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                let url = new URL("/api/personal/distributors", window.location.href);
                url.searchParams.set("page", ctx.current_page)

                fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(response => {

                        if (response.code === 200) {
                            send({
                                type: "DONE_TABLE",
                                data: response
                            })
                        } else {
                            send({
                                type: "ERROR_TABLE",
                                data: response
                            })

                        }
                    })
            }
        }
    },
    "guards": {
        "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
        "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,
    }
});