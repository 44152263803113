import styles from "./AccountLayout.module.scss";
import {Outlet} from "react-router-dom";
import {AccountMenu} from "../AccountMenu/AccountMenu";
import {Logo} from "../Header/Header";

export function AccountLayout() {
    return (
        <main id="main" className={styles.flexContainer} role="main">
            <aside className={styles.sidebar}>
                <Logo dark/>
                <AccountMenu/>
            </aside>
            <section className={styles.workarea}>
                <Outlet/>
            </section>
        </main>
    )
}