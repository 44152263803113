import React from 'react';
import ReactDOM from 'react-dom';

/*import "./js/server/server";*/

import App from './App';
import "./scss/main.scss";

/*import {inspect} from "@xstate/inspect";

if (process.env.NODE_ENV === 'development') {
    inspect();
}*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
