import {assign, createMachine, interpret} from "xstate";

/*
* В этой машине хранится состояние авторизации.
* При первом открытии страницы выполняется проверка:
* 1. Есть ли в локальном хранилище токен
* 2. Если токен есть, посылается запрос "/api/init", который проверяет, действителен ли он
 */

const authMachine = createMachine({
        id: "authorization",
        context: {
            type: undefined,
            name: "",
            balance: 0,
            image: "",
            can_upload: undefined,
            errorMsg: []
        },
        initial: "idle",
        states: {
            "idle": {
                invoke: {
                    src: "check",
                    id: "check",
                    onDone: {
                        target: "auth"
                    },
                    onError: {
                        target: "unAuth"
                    }
                },
            },
            "unAuth": {
                invoke: {
                    id: "clearToken",
                    src: "clearToken"
                },
                on: {
                    "login": {
                        target: "loading"
                    }
                }
            },
            "loading": {
                invoke: {
                    id: "authentication",
                    src: "authentication"
                },
                on: {
                    "success": {
                        target: "auth"
                    },
                    "failure": {
                        target: "unAuth",
                        actions: ["saveError"]
                    }
                }
            },
            "auth": {
                initial: "noData",
                states: {
                    "noData": {
                        invoke: {
                            src: "getUserData",
                            id: "getUserData",

                        },
                        on: {
                            done: {
                                target: "gotData",
                                actions: ["saveUserData", "clearError"]
                            },
                            error: {
                                target: "error",
                                actions: ["saveError"]
                            }
                        }
                    },
                    "error": {},
                    "gotData": {

                    }
                },
                on: {
                    "logout": {
                        target: "unAuth",
                    },
                    "refresh": {
                        target: "auth.noData"
                    }
                }
            }
        }
    },
    {
        actions: {
            "saveUserData": assign({
                "type": (ctx, message) => message.data.type,
                "name": (ctx, message) => message.data.name,
                "balance": (ctx, message) => message.data.balance,
                "image": (ctx, message) => message.data.image,
                "can_upload": (ctx, message) => message.data.can_upload,
            }),
            "saveError": assign({
                "errorMsg": (ctx, message) => message.data.message
            }),
            "clearError": assign({
                "errorMsg": ""
            })
        },
        services: {
            "check": () => {
                let token = localStorage.getItem('sessionID');

                if (token) {
                    return fetch("/api/init", {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`,
                            "Accept": "application/json",
                        },
                    })
                        .then(response => response.json())
                        .then(response => {
                            return response.code === 401
                                ? Promise.reject("Unauthorized")
                                : Promise.resolve()
                        })
                } else {
                    return Promise.reject()
                }
            },
            "authentication": (ctx, message) => {
                return function (send) {
                    fetch("/api/auth", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        },
                        body: JSON.stringify(message.data)

                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.code === 200) {
                                localStorage.setItem('sessionID', response.token);
                                send({
                                    type: "success",
                                    data: response
                                })
                            } else {
                                send({
                                    type: "failure",
                                    data: response
                                })
                            }
                        })
                }
            },
            "clearToken": () => {
                let token = localStorage.getItem("sessionID");
                if (token) {
                    localStorage.removeItem("sessionID")
                }
            },
            "getUserData": () => {
                let token = localStorage.getItem("sessionID");

                if (token) {
                    return function (send) {
                        fetch("/api/personal", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`,
                                "Accept": "application/json"
                            }
                        })
                            .then(response => response.json())
                            .then(response => {
                                if (response.code === 200) {
                                    send({
                                        type: "done",
                                        data: response,
                                    })
                                } else {
                                    send({
                                        type: "error",
                                        data: response
                                    })
                                }
                            })
                    }
                }
            }
        }
    });

export const AuthState = interpret(authMachine, {devTools: true}).start();

