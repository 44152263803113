import {Link} from "react-router-dom";
import {useMachine} from "@xstate/react";
import {producerStatMachine} from "../../xstate/producer.machine";
import styles from "./Producer.module.scss";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";
import {ChartSelect} from "../ChartSelect/ChartSelect";
import {periodsList} from "../../constants";
import clsx from "clsx";
import {Loader} from "../Loader/Loader";
import {ChartArea} from "../ChartArea/ChartArea";

export function ProducerStat() {

    let [stateProducer, sendProducer] = useMachine(producerStatMachine);

    return (<>
            <h1>Статистика дистрибьюторов</h1>

            {
                stateProducer.context.errorMsg.length > 0 &&
                stateProducer.context.errorMsg.map((item, index) => {
                    return <div className="errorMessage" key={index}>
                        {item}
                    </div>
                })
            }

            <section className={clsx("statContainer", styles.chartArea)}>
                <div className={styles.titleBlock}>
                    <h2>График продаж</h2>

                    <div className={styles.chartControls}>
                        <ChartSelect
                            send={sendProducer}
                            type={"CHANGE_FILTER"}
                            dataName={"distributor"}
                            label={"Дистрибьютор: "}
                            name={"distributor"}
                            default={stateProducer.context.current_distributor.toString()}
                            options={stateProducer.context.distributors}
                        />
                        <ChartSelect
                            send={sendProducer}
                            type={"CHANGE_FILTER"}
                            dataName={"period"}
                            label={"Выбор периода"}
                            name={"periodSelect"}
                            default={"week"}
                            options={[...periodsList]}
                        />
                    </div>
                </div>

                {
                    stateProducer.matches({chart: "loading"})
                        ? <Loader/>
                        : <ChartArea
                            chart_data={stateProducer.context.chart_data}
                        />
                }
            </section>
            <section className={"statContainer"}>
                <h2 className="visuallyHidden">Таблица продаж</h2>

                {
                    stateProducer.context.list.length > 0
                        ? <>
                            <DistributorsTable
                                data={stateProducer.context.list}
                            />
                            {
                                stateProducer.context.pages > 1 &&
                                <Pagination
                                    pages={stateProducer.context.pages}
                                    current_page={stateProducer.context.current_page}
                                    send={sendProducer}
                                />
                            }
                        </>
                        : <NoData/>
                }
            </section>
        </>
    )
}

function DistributorsTable(props) {
    if (props.data.length === 0) {
        return <NoData/>
    } else {
        return <div className="scrollbox">
            <table id={"distrTable"} className={"statTable"}>
                <thead>
                <tr>
                    <th>Дистрибьюторы</th>
                    <th>Регион</th>
                    <th>Торговых точек</th>
                    <th>Продано ед.</th>
                    <th>Сумма продаж</th>
                    <th>Кол-во исп. баллов</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => {
                        return <tr key={item.id}>
                            <td className={styles.itemName}>
                                <Link to={`distr${item.id}`}>
                                    {item.name}
                                </Link>
                            </td>
                            <td>
                                {item.region}
                            </td>
                            <td>
                                {item.shops_quantity}
                            </td>
                            <td>
                                {new Intl.NumberFormat('ru-RU').format(item.items_sold)} &#8381;
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.points)} баллов
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}