import styles from "./MoreBonuses.module.scss";
import {useEffect, useState} from "react";
import clsx from "clsx";
import {Loader} from "../Loader/Loader";

export function MoreBonuses() {
    let [moreBonuses, setMoreBonuses] = useState({});
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetch("/api/more-bonuses", {
            method: "GET",
            header: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => setMoreBonuses(response));
        setLoading(false)
    }, []);

    return (<>
        {loading && <Loader/>}
        {
            moreBonuses.items?.length === 0
                ? ""
                : <section className={styles.moreBonuses}>
                    <div className={clsx("wrapper", styles.wrapper)}>
                        <div className={styles.textSection}>
                            <h2>Получите повышенные баллы</h2>
                            <p>
                                {moreBonuses.text}
                            </p>
                            <ul className={styles.itemsList}>
                                {
                                    moreBonuses?.items?.map((elem, index) => {
                                        return (
                                            <li key={`el${index}`}>
                                                {elem.name}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={styles.imageSection}>
                            <img src={moreBonuses?.image} alt=""/>
                        </div>
                    </div>
                </section>
        }
    </>)
}