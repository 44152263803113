import styles from "./AccountMenu.module.scss"
import {Link, NavLink} from "react-router-dom";
import {Icon} from "../SVGsprite/svgsprite";
import {useActor} from "@xstate/react";
import {AuthState} from "../../xstate/auth.machine";

export function AccountMenu(props) {

    let [stateAuth, sendAuth] = useActor(AuthState);

    return (
        <section className={styles.accMenu}>
            {
                stateAuth.context.errorMsg.length > 0 &&
                <>
                    {stateAuth.context.errorMsg.map((item, index) => {
                        return (
                            <div className={"errorMessage"} key={index}>
                                item
                            </div>
                        )
                    })
                    }
                </>
            }
            <div className={styles.profile}>
                <div className={styles.userInfo}>
                    <div className={styles.userpic}>
                        <img src={
                            stateAuth.context.image
                                ? stateAuth.context.image
                                : "/images/user-ph.png"
                        } alt=""/>
                    </div>
                    <div className={styles.userName}>
                        {
                            stateAuth.context.name
                        }
                    </div>
                    <Link onClick={() => props.dialog?.hide()} to={"/account"}>Редактировать профиль</Link>

                    {
                        (stateAuth.context.type === "seller" || stateAuth.context.type === "owner")
                            ? <div className={styles.balance}>
                                Счет: {new Intl.NumberFormat('ru-RU').format(stateAuth.context.balance)} баллов
                            </div>
                            : ""
                    }

                </div>

                <hr/>
                <nav
                    className={styles.navigation}
                    aria-label={"Навигация по личному кабинету"}
                >
                    {
                        (stateAuth.context.type === "seller" || stateAuth.context.type === "owner") &&
                        <NavLink
                            className={"btn btnSecondary btnIcon"}
                            to={"receipts"}
                            onClick={() => props.dialog?.hide()}
                        >
                            <Icon name={"icon-list"} sizeW="16" sizeH="16"/>
                            Мои чеки
                        </NavLink>
                    }

                    {
                        (stateAuth.context.type === "seller") &&
                        <NavLink
                            className={"btn btnSecondary btnIcon"}
                            to={"sales"}
                            onClick={() => props.dialog?.hide()}
                        >
                            <Icon name={"icon-chart"} sizeW="16" sizeH="16"/>
                            Мои продажи
                        </NavLink>
                    }
                    {
                        (stateAuth.context.type !== "seller") &&
                        <NavLink
                            className={"btn btnSecondary btnIcon"}
                            to={"statistics"}
                            onClick={() => props.dialog?.hide()}
                        >
                            <Icon name={"icon-chart"} sizeW="16" sizeH="16"/>
                            Статистика
                        </NavLink>
                    }

                    {
                        (stateAuth.context.type === "seller" || stateAuth.context.type === "owner") &&
                        <NavLink
                            className={"btn btnSecondary btnIcon"}
                            to={"gifts"}
                            onClick={() => props.dialog?.hide()}
                        >
                            <Icon name={"icon-starStroke"} sizeW="16" sizeH="16"/>
                            Мои подарки
                        </NavLink>
                    }

                </nav>
            </div>

            <div className={styles.logout}>
                <button
                    className={"btn btnSecondary btnIcon"}
                    onClick={()=> sendAuth({type:"logout"})}
                >
                    <Icon name={"icon-logout"} sizeW="16" sizeH="16"/>
                    Выйти из&nbsp;профиля
                </button>
            </div>
        </section>
    )
}