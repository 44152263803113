import {useEffect, useState} from "react";
import styles from "./TopSales.module.scss";
import {Loader} from "../Loader/Loader";

export function TopSells(props) {
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState([]);

    useEffect(() => {
        let token = localStorage.getItem('sessionID');

        setLoading(true);
        fetch("/api/personal/top", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(response => response.json())
            .then(response => {
                let hasCode = Object.keys(response).includes("code");

                if (hasCode) {
                    setError(response.message)
                } else {
                    setData(response)
                }
            })

        setLoading(false);
    }, [])

    if (data.length > 0) {
        return (
            <>
                {
                    error.length>0 && error.map((item, index)=><div className={"errorMessage"} key={index}>
                        {item}
                    </div>)
                }

                {
                    loading
                        ? <Loader/>
                        : <>
                            {
                                data.map(
                                    (item) => <article key={item.id} className={styles.announce}>
                                            <h2>{item.name}</h2>

                                            <p className={styles.money}>
                                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                                            </p>
                                            <p>{item.note}</p>
                                        </article>
                                )
                            }
                        </>
                }
            </>
        )
    } else {
        return ""
    }
}