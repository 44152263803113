import {assign, createMachine} from "xstate";

export let userCardMachine = createMachine({
    id: "userCard",
    initial: "requestData",
    context: {
        errorMessage: [],
        userData: {},
    },
    states: {
        requestData: {
            invoke: {
                id: "getData",
                src: "getData",
            },
            on: {
                done: {
                    target: "success",
                    actions: ["saveData"],
                },
                error: {
                    target: "failure",
                    actions: ["saveError", "scrollTop"],
                },
            },
        },
        sendData: {
            invoke: {
                id: "postData",
                src: "postData",
            },
            on: {
                "done": {
                    target: "success",
                },
                "error": {
                    target: "failure",
                    actions: ["saveError", "scrollTop"],
                },
            },
        },
        failure: {
            on: {
                "update": {
                    target: "sendData",
                },
            }
        },
        success: {
            on: {
                "deleteAvatar": {
                    target: "processing",
                },
                "update": {
                    target: "sendData",
                    actions: ["resetError"]
                },
            },
        },
        processing: {
            invoke: {
                id: "deleteAvatar",
                src: "deleteAvatar",
            },
            on: {
                done: {
                    target: "requestData",
                },
                error: {
                    target: "failure",
                    actions: ["scrollTop"]
                },
            },
        },
    },
}, {
    actions: {
        "saveData": assign({
            "userData": (ctx, message) => message.data
        }),
        "saveError": assign({
            "errorMessage": (ctx, message) => message.data
        }),
        "resetError": assign({
            "errorMessage": []
        }),
        "scrollTop": () => {
            /*document.getElementById("receiptsTable")?.scrollTo(0, 0);*/
            window.scrollTo(0, 0);
        }
    },
    services: {
        "getData": () => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                fetch("/api/personal/full", {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Accept": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 200) {
                            send({
                                type: "done",
                                data: response
                            })
                        } else {

                            send({
                                type: "error",
                                data: response
                            })
                        }
                    })

            }
        },
        "postData": (ctx, message) => {
            return function (send) {
                let token = localStorage.getItem("sessionID");
                let FD = new FormData(message.data);

                let old_pass = message.data.old_password.value,
                    new_pass = message.data.new_password.value;

                if (
                    (old_pass === "" && new_pass === "") ||
                    (old_pass !== "" && new_pass !== "")
                ) {
                    fetch("/api/personal/update", {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "Accept": "application/json"
                        },
                        body: FD
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.code !== 200) {
                                send({
                                    type: "error",
                                    data: response.message
                                })
                            } else {
                                send({type: "done"})
                            }
                        });
                } else if (old_pass === "" || new_pass === "") {
                    send({
                        type: "error",
                        data: ["Для смены пароля заполните оба поля"]
                    })
                }

            }
        },
        "deleteAvatar": () => {
            let token = localStorage.getItem('sessionID');

            if (token) {
                return function (send) {
                    fetch("/api/personal/delete-avatar", {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            "Accept": "application/json"
                        },
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.code === 200) {
                                send({type: "done"})
                            } else {
                                send({type: "error", data: response.message})
                            }
                        })
                }
            }
        }
    }
});