import {useEffect, useState} from "react";
import {Loader} from "../Loader/Loader";
import clsx from "clsx";
import styles from "./Bonuses.module.scss";

export function Bonuses() {
    let [moreBonuses, setMoreBonuses] = useState({});
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch("/api/more-bonuses", {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.code === 200) {
                    setMoreBonuses(response)
                }
            });
        setLoading(false);
    }, []);

    if (Object.entries(moreBonuses).length > 0) {
        return (
            <>
                {loading && <Loader/>}
                {
                    moreBonuses.items?.length === 0
                    ? ""
                    : <article className={clsx(styles.announce, styles.bonuses)}>
                            <h2>Получите повышенные баллы</h2>
                            <img
                                className={styles.cover}
                                src="/images/zcoin-cover.png"
                                alt=""
                            />
                            <p>{moreBonuses.text}</p>

                            <ul className={styles.goodies}>
                                {
                                    moreBonuses.items?.map(
                                        (item) => <li key={item.id}>
                                            <img src={item.image} alt=""/>
                                            <span>
                                                {item.name}
                                            </span>
                                        </li>)
                                }
                            </ul>
                        </article>
                }
            </>)
    } else {
        return null
    }
}