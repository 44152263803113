import {useEffect} from "react";
import {useActor} from "@xstate/react";
import {AuthState} from "../../xstate/auth.machine";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {Icon} from "../SVGsprite/svgsprite";
import styles from "./Statistics.module.scss";
import {TopSells} from "../TopSells/TopSells";
import {Loader} from "../Loader/Loader";

export function Statistics() {

    let [stateAuth, sendAuth] = useActor(AuthState);
    let params = useParams();

    useEffect(() => {
        document.title = "Статистика | Программа лояльности SK ZIC";
    }, []);

    return(<>
            {
                stateAuth.matches("loading") || stateAuth.matches("idle")

                    ? <div className={"fullPageLoader"}><Loader/></div>
                    : <>
                        <section className={styles.workarea}>
                            <nav aria-label={"Хлебные крошки"} className="breadcrumbs">
                                <NavLink to="/account/statistics">Статистика</NavLink>
                                {
                                    params.distributorID &&
                                    <>
                        <span>
                            <Icon
                                name={"icon-bcrumb"}
                                sizeH="10"
                                sizeW="6"
                            />
                        </span>
                                        <NavLink to={`/statistics/${params.distributorID}/${params.shopID}/${params.sellerID}`}>
                                            Статистика торговых точек
                                        </NavLink>
                                    </>
                                }
                                {
                                    params.shopID &&
                                    <>
                        <span>
                            <Icon
                                name={"icon-bcrumb"}
                                sizeH="10"
                                sizeW="6"
                            />
                        </span>
                                        <NavLink to={`/statistics/${params.shopID}/${params.shopID}`}>
                                            Статистика магазина
                                        </NavLink>
                                    </>
                                }
                                {
                                    params.sellerID &&
                                    <>
                        <span>
                            <Icon
                                name={"icon-bcrumb"}
                                sizeH="10"
                                sizeW="6"
                            />
                        </span>
                                        <NavLink to={`/statistics/${params.sellerID}`}>
                                            Статистика продавца
                                        </NavLink>
                                    </>
                                }


                            </nav>

                            <Outlet/>
                        </section>
                        <aside  className={styles.sidebar}>
                            {
                                (stateAuth.context.type === "producer" || stateAuth.context.type === "distributor") &&
                                <TopSells/>
                            }
                        </aside>
                    </>
            }

    </>
    )
}