import styles from "./MyInfo.module.scss";
import {useEffect, useState} from "react";
import {useActor, useMachine} from "@xstate/react";
import {AuthState} from "../../xstate/auth.machine";
import clsx from "clsx";
import {Popover, PopoverDisclosure, usePopoverState} from "reakit";
import {Icon} from "../SVGsprite/svgsprite";
import {userCardMachine} from "../../xstate/userCard.machine";
import {Loader} from "../Loader/Loader";

export function MyInfo() {
    let [stateAuth, sendAuth] = useActor(AuthState);
    let [stateUserCard, sendUserCard] = useMachine(userCardMachine);

    useEffect(() => {
        document.title = "Редактировать профиль | Программа лояльности SK ZIC";
    }, []);

    return (
        <section className={styles.myInfo}>
            <h1>Редактировать профиль</h1>

            {
                (stateUserCard.context.errorMessage.length > 0) &&
                stateUserCard.context.errorMessage.map((item, index) => {
                    return <div className={"errorMessage"} key={index}>{item}</div>

                })
            }

            {
                stateUserCard.matches("requestData") ||
                stateUserCard.matches("sendData") ||
                stateUserCard.matches("processing")
                    ? <div className={"fullPageLoader"}><Loader/></div>
                    : <UserCard
                        data={stateUserCard.context.userData}
                        sendUserCard={sendUserCard}
                    />
            }

    </section>
    )
}

function UserCard (props) {
    let [stateAuth] = useActor(AuthState);
    let [phoneVal, setPhoneVal] = useState(props.data.phone);

    /* Popovers */
    const popoverName = usePopoverState({placement: "left"});
    const popoverEmail = usePopoverState({placement: "left-end"});
    const popoverShop = usePopoverState({placement: "left-end"});

    function update (event) {
        event.preventDefault();

        props.sendUserCard({
            type: "update",
            data: event.target
        })
    }

    return (
        <form
            onSubmit={(event) => update(event)}
        >
            <div className={clsx(styles.userpicRow, "formRow")}>
                <div className={styles.userpic}>
                    <img
                        src={
                            props.data.avatar !== ""
                                ? props.data.avatar
                                : "/images/user-ph.png"
                        }
                        alt="Фото пользователя"
                    />
                </div>
                <div className={styles.buttons}>
                    <div className="customFileInput">
                        <input id={"image"} name="image" type="file" accept="image/*"/>
                        <label htmlFor={"image"}>
                            {
                                props.data.avatar.length > 0
                                    ? "Сменить фото"
                                    : "Добавить фото"
                            }
                        </label>
                    </div>
                    {
                        props.data.avatar.length > 0 &&
                        <button
                            type={"button"}
                            className={"btn btnContour btnSmall"}
                            onClick={
                                ()=>sendUserCard({type: "deleteAvatar"})
                            }
                        >
                            Удалить фото
                        </button>
                    }
                </div>
            </div>

            {
                (stateAuth.context.type === "seller" || stateAuth.context.type === "owner") &&
                <div className="formRow">
                    <label htmlFor="userName">Имя</label>
                    <div className="inputWrapper">
                        <input type="text" id="userName" defaultValue={props.data.name} disabled/>
                        <PopoverDisclosure {...popoverName}>
                        <span className="visuallyHidden">
                            Открыть подсказку
                        </span>
                            <Icon
                                name={"icon-question"}
                                sizeW="24"
                                sizeH="24"
                                sizeWV="32"
                                sizeHV="32"
                            />
                        </PopoverDisclosure>
                        <Popover className={"popover"} {...popoverName} aria-label="Подсказка">
                            Что бы внести изменения свяжитесь с&nbsp;администратором по&nbsp;телефону <a
                            href={"tel:+74959894757"}>+7 (495) 989 47 57</a> или почте <a
                            href={"mailto:admin@zicoil.ru"}>admin@zicoil.ru</a>
                        </Popover>
                    </div>
                </div>
            }
            {
                (stateAuth.context.type === "distributor") &&
                <div className="formRow">
                    <label htmlFor="company">Название компании или ИП</label>
                    <div className="inputWrapper">
                        <input
                            type="text"
                            id="company"
                            defaultValue={props.data.name}
                            disabled
                        />
                        <PopoverDisclosure {...popoverShop}>
                                    <span className="visuallyHidden">
                                        Открыть подсказку
                                    </span>
                            <Icon
                                name={"icon-question"}
                                sizeW="24"
                                sizeH="24"
                                sizeWV="32"
                                sizeHV="32"
                            />
                        </PopoverDisclosure>
                        <Popover className={"popover"} {...popoverShop} aria-label="Подсказка">
                            Что бы внести изменения свяжитесь с&nbsp;администратором по&nbsp;телефону <a
                            href={"tel:+74959894757"}>+7 (495) 989 47 57</a> или почте <a
                            href={"mailto:admin@zicoil.ru"}>admin@zicoil.ru</a>
                        </Popover>
                    </div>
                </div>
            }
            <div className="formRow">
                <label htmlFor="email">Email</label>
                <div className="inputWrapper">
                    <input
                        type="text"
                        id="email"
                        defaultValue={props.data.email}
                        disabled
                    />
                    <PopoverDisclosure {...popoverEmail}>
                                <span className="visuallyHidden">
                                    Открыть подсказку
                                </span>
                        <Icon
                            name={"icon-question"}
                            sizeW="24"
                            sizeH="24"
                            sizeWV="32"
                            sizeHV="32"
                        />
                    </PopoverDisclosure>
                    <Popover className={"popover"} {...popoverEmail} aria-label="Подсказка">
                        Что бы внести изменения свяжитесь с&nbsp;администратором по&nbsp;телефону <a
                        href={"tel:+74959894757"}>+7 (495) 989 47 57</a> или почте <a
                        href={"mailto:admin@zicoil.ru"}>admin@zicoil.ru</a>
                    </Popover>
                </div>
            </div>
            {
                (stateAuth.context.type === "seller" || stateAuth.context.type === "owner") &&
                <div className="formRow">
                    <label htmlFor="shopName">Торговая точка</label>
                    <div className="inputWrapper">
                        <input
                            type="text"
                            id="shopName"
                            defaultValue={props.data.shop}
                            disabled
                        />
                        <PopoverDisclosure {...popoverShop}>
                                    <span className="visuallyHidden">
                                        Открыть подсказку
                                    </span>
                            <Icon
                                name={"icon-question"}
                                sizeW="24"
                                sizeH="24"
                                sizeWV="32"
                                sizeHV="32"
                            />
                        </PopoverDisclosure>
                        <Popover className={"popover"} {...popoverShop} aria-label="Подсказка">
                            Что бы внести изменения свяжитесь с&nbsp;администратором по&nbsp;телефону <a
                            href={"tel:+74959894757"}>+7 (495) 989 47 57</a> или почте <a
                            href={"mailto:admin@zicoil.ru"}>admin@zicoil.ru</a>
                        </Popover>
                    </div>
                </div>
            }
            <div className="formRow">
                <label htmlFor="phone">Номер телефона</label>
                <div className="inputWrapper telWrapper">
                    <input
                        type="tel"
                        className={"phone"}
                        id="phone"
                        name="phone"
                        minLength={10}
                        pattern={"[0-9]{10}"}
                        value={
                            phoneVal ? phoneVal : ""
                        }
                        onChange={(event) => setPhoneVal(parseInt(event.target.value))}
                    />
                </div>
                <span className={"note"}>Формат: 10 цифр</span>
            </div>

            <h2>Сменить пароль</h2>
            <div className="formRow">
                <label htmlFor="old_password">Текущий пароль</label>
                <input
                    id="old_password"
                    name="old_password"
                    type="password"
                    autoComplete="current-password"
                    minLength={8}
                />
            </div>
            <div className="formRow">
                <label htmlFor="new_password">Новый пароль</label>
                <input
                    id="new_password"
                    name="new_password"
                    type="password"
                    autoComplete="new-password"
                    minLength={8}
                />
            </div>
            <div className="formRow">
                <button type="submit" className={"btn btnPrimary"}>Обновить данные</button>
            </div>
        </form>
    )
}