import clsx from "clsx";

export function ChartSelect(props) {

    let changeParameter = function (event) {
        props.send(
            {
                type: props.type,
                data: {
                    [props.dataName]: event.target.value
                }
            }
        );
    };

    return <div className={"customSelect"}>
        <label
            htmlFor={props.name}
            className={clsx({["visuallyHidden"]: props.dataName === "period"})}
        >
            {props.label}
        </label>
        <div className="selectContainer">

        <select
            name={props.name}
            id={props.name}
            onChange={
                (event) => changeParameter(event)
            }
            defaultValue={props.default}
        >
            {
                props.default === "0" && <option value="0">Все</option>
            }

            {
                props.options?.map(
                    (item) => {
                        return <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    }
                )
            }
        </select>
    </div>
        </div>
}