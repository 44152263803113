import styles from "./Pagination.module.scss";
import clsx from "clsx";
import {Icon} from "../SVGsprite/svgsprite";

export function Pagination(props) {

    return (
        <section className={styles.pagination} aria-label={"Навигация по страницам раздела"}>
            <div className={clsx("wrapper", styles.wrapper)}>
                <button
                    onClick={() => props.send("prevPage")}
                    className={clsx(styles.btnArrow)}
                >
                <span className="visuallyHidden">
                    Предыдущая страница
                </span>
                    <Icon name={"icon-prev"} sizeW="9" sizeH="16"/>
                </button>

                <div className={styles.pages} aria-live={"polite"}>
                    <span className={"visuallyHidden"}>Страница </span>
                    <span className={clsx(styles.page, styles.pageFirst)}>
                        {props.current_page}
                    </span>
                    <span aria-hidden="true">&#160;/&#160;</span>
                    <span className={"visuallyHidden"}> из </span>
                    <span className={styles.page}>
                        {props.pages}
                    </span>
                </div>

                <button
                    onClick={() => props.send("nextPage")}
                    className={clsx(styles.btnArrow)}
                >
                <span className="visuallyHidden">
                    Следующая страница
                </span>
                    <Icon name={"icon-next"} sizeW="9" sizeH="16"/>
                </button>
            </div>
        </section>
    )
}