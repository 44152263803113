import {assign, createMachine} from "xstate";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

let defaultContext = {
    date: "",
    dateError: "",
    time: "",
    timeError: "",
    image: undefined,
    imageError: "",
    fd: undefined,
    fdError: "",
    fn: undefined,
    fnError: "",
    fp: undefined,
    fpError: "",
    sum: undefined,
    sumError: "",
    errorMsg: [], // ошибки сервера
};

export const uploadReceiptMachine = createMachine(
    {
        id: "upload-receipt",
        type: "parallel",
        context: {...defaultContext},
        states: {
            type: {
                initial: "photo",
                states: {
                    "photo": {
                        entry: ["scrollTop"],
                        on: {
                            "TOGGLE": {
                                actions: ["resetErrors"],
                                target: "fields"
                            }
                        }
                    },
                    "fields": {
                        entry: ["scrollTop"],
                        on: {
                            "TOGGLE": {
                                actions: ["resetErrors"],
                                target: "photo"
                            }
                        }
                    }
                }
            },
            form: {
                initial: "idle",
                states: {
                    idle: {
                        on: {
                            "INPUT": {
                                actions: ["saveData"],
                                internal: true
                            },
                            "SUBMIT_PHOTO": {
                                actions: ["resetErrors"],
                                target: "validating_photo",
                            },
                            "SUBMIT_FIELDS": {
                                actions: ["resetErrors"],
                                target: "validating_fields"
                            }
                        },
                    },
                    validating_photo: {
                        invoke: {
                            id: "validate_photo",
                            src: "validate_photo",

                        },
                        on: {
                            "VALID": {
                                target: "sending_photo",
                            },

                            "INVALID": {
                                actions: ["saveErrors"],
                                target: "show_errors",
                            },
                        },
                    },
                    validating_fields: {
                        invoke: {
                            id: "validate_fields",
                            src: "validate_fields",
                        },
                        on: {
                            "VALID": {
                                target: "sending_fields",
                            },

                            "INVALID": {
                                actions: ["saveErrors"],
                                target: "show_errors",
                            },
                        },
                    },
                    show_errors: {
                        entry: ["scrollTop"],
                        // показываем ошибки после валидации
                        on: {
                            "INPUT": {
                                actions: ["saveData"],
                                internal: true
                            },
                            "SUBMIT_PHOTO": {
                                actions: ["resetErrors"],
                                target: "validating_photo",
                            },
                            "SUBMIT_FIELDS": {
                                actions: ["resetErrors"],
                                target: "validating_fields",
                            }
                        },
                    },
                    sending_photo: {
                        invoke: {
                            id: "sendReceipt_photo",
                            src: "sendReceipt_photo"
                        },
                        on: {
                            "DONE_PHOTO": {
                                target: "success",
                            },
                            "ERROR_PHOTO": {
                                target: "failure",
                                actions: ["setServerError"]
                            },
                        },
                    },
                    sending_fields: {
                        invoke: {
                            id: "sendReceipt_fields",
                            src: "sendReceipt_fields"
                        },
                        on: {
                            "DONE_FIELDS": {
                                target: "success",
                            },
                            "ERROR_FIELDS": {
                                target: "failure",
                                actions: ["setServerError"]
                            },
                        },
                    },
                    success: {
                        entry: ["resetContext"],
                        on: {
                            "ONE_MORE": {
                                "target": "idle"
                            }
                        }
                    },
                    failure: {
                        // ошибка отправки
                        on: {
                            "INPUT": {
                                actions: ["saveData"],
                                internal: true
                            },
                            "SUBMIT_PHOTO": {
                                actions: ["resetErrors"],
                                target: "validating_photo",
                            },
                            "SUBMIT_FIELDS": {
                                actions: ["resetErrors"],
                                target: "validating_fields",
                            }
                        }
                    },
                }
            }
        },
    },
    {
        actions: {
            "saveData": assign(
                (ctx, message) => message.data
            ),
            "saveErrors": assign(
                (ctx, message) => message.data
            ),
            "resetErrors": assign(
                {
                    dateError: "",
                    timeError: "",
                    imageError: "",
                    fdError: "",
                    fnError: "",
                    fpError: "",
                    sumError: "",
                    errorMsg: []
                }
            ),
            "setServerError": assign({
                "errorMsg": (ctx, message) => message.data
            }),
            "scrollTop": () => {
                /*document.getElementById("root").scrollTo(0, 0);*/
                window.scrollTo(0, 0);
            }
        },
        services: {
            "validate_photo": (ctx) => {
                return function (send) {
                    let fd = validateFDFP(ctx.fd);
                    let errors = {
                        "imageError": "",
                        "fdError": ""
                    };

                    if (!ctx.image) {
                        errors.imageError = "Поле не может быть пустым";
                    }

                    if (fd !== "ok") {
                        errors.fdError = fd
                    }

                    // Если хотя бы одно из значений ключей в объекте errors не является пустой строкой,
                    // уходим в состояние показа ошибок
                    let haveErrors = Object.values(errors).some(el => el !== "");

                    if (haveErrors) {
                        send({
                            type: "INVALID",
                            data: errors
                        })
                    } else {
                        send({type: "VALID"})
                    }
                }
            },
            "validate_fields": (ctx) => {

                return function (send) {
                    let date = validateDate(ctx.date),
                        time = validateTime(ctx.time),
                        fd = validateFDFP(ctx.fd),
                        fp = validateFDFP(ctx.fp),
                        fn = validateFN(ctx.fn),
                        sum = validateSum(ctx.sum),
                        errors = {
                            dateError: "",
                            timeError: "",
                            fdError: "",
                            fnError: "",
                            fpError: "",
                            sumError: ""
                        };

                    if (date !== "ok") {
                        errors.dateError = date
                    }

                    if (time !== "ok") {
                        errors.timeError = time
                    }

                    if (fd !== "ok") {
                        errors.fdError = fd
                    }

                    if (fp !== "ok") {
                        errors.fpError = fp
                    }

                    if (fn !== "ok") {
                        errors.fnError = fp
                    }

                    if (sum !== "ok") {
                        errors.sumError = sum
                    }

                    // Если хотя бы одно из значений ключей в объекте errors не является пустой строкой,
                    // уходим в состояние показа ошибок
                    let haveErrors = Object.values(errors).some(el => el !== "");

                    if (haveErrors) {
                        send({
                            type: "INVALID",
                            data: errors
                        })
                    } else {
                        send({type: "VALID"})
                    }
                }
            },
            "sendReceipt_fields": (ctx, message, meta) => {
                return function (send) {
                    let token = localStorage.getItem('sessionID');

                    let FD = new FormData();
                    FD.append("image", "")
                    FD.append("fd", ctx.fd)
                    FD.append("fn", ctx.fn)
                    FD.append("fp", ctx.fp)
                    FD.append("date", ctx.date)
                    FD.append("time", ctx.time)
                    FD.append("sum", ctx.sum)

                    fetch("/api/personal/upload", {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${token}`
                        },
                        body: FD
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.code === 200) {
                                send({
                                    type: "DONE_FIELDS"
                                })
                            } else {
                                send({
                                    type: "ERROR_FIELDS",
                                    data: response.message
                                })
                            }
                        })
                }
            },
            "sendReceipt_photo": (ctx, message) => {
                return function (send) {
                    let token = localStorage.getItem('sessionID');

                    let FD = new FormData();

                    FD.append("image", ctx.image)
                    FD.append("fd", ctx.fd)
                    FD.append("fn", "")
                    FD.append("fp", "")
                    FD.append("date", "")
                    FD.append("time", "")
                    FD.append("sum", "")

                    fetch("/api/personal/upload", {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${token}`
                        },
                        body: FD
                    })
                        .then(response => response.json())
                        .then(response => {
                            if (response.code === 200) {
                                send({
                                    type: "DONE_PHOTO"
                                })
                            } else {
                                send({
                                    type: "ERROR_PHOTO",
                                    data: response.message
                                })
                            }
                        })
                }
            }
        },

    }
);

function validateDate(value) {

    if (!value) {
        return "Поле не может быть пустым.";
    }

    let parsedDate = dayjs(value, "DD.MM.YYYY", "ru", true);

    if (parsedDate.isValid()) {
        return "ok"
    } else {
        return "Поле заполнено неверно. Формат: 01.01.2022"
    }
}

function validateTime(value) {

    let parsedTime = dayjs(value, "HH:mm", "ru", true);

    if (!value) {
        return "Поле не может быть пустым.";
    }

    if (parsedTime.isValid()) {
        return "ok"
    } else {
        return "Поле заполнено неверно. Формат: 03:07"
    }
}

function validateFDFP(value) {
    let format = /^\d{1,10}$/;

    if (!value) {
        return "Поле не может быть пустым"
    }

    if (format.test(value)) {
        return "ok"
    } else {
        return "Поле заполнено неверно. Введите до 10 цифр."
    }
}

function validateFN(value) {
    let format = /^\d{1,16}$/;

    if (!value) {
        return "Поле не может быть пустым"
    }

    if (format.test(value)) {
        return "ok"
    } else {
        return "Поле заполнено неверно. ФН состоит из 16 цифр."
    }
}

function validateSum(value) {
    let format = /^\d+\.\d{2}$/;

    if (format.test(value)) {
        return "ok"
    } else {
        return "Поле заполнено неверно. Формат: 2500.00"
    }
}