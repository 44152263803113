import {Link, useParams} from "react-router-dom";
import {useMachine} from "@xstate/react";
import {ownerStatMachine} from "../../xstate/ownerstat.machine";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";
import {ChartSelect} from "../ChartSelect/ChartSelect";
import clsx from "clsx";
import styles from "./ShopStat.module.scss";
import {periodsList} from "../../constants";
import {Loader} from "../Loader/Loader";
import {ChartArea} from "../ChartArea/ChartArea";


export function ShopStat () {

    let {shopID = ""} = useParams();

    let [stateShop, sendShop] = useMachine(ownerStatMachine, {
        context: {
            ...ownerStatMachine.context,
            id: shopID.replace(/shop/g, "")
        }
    });

    return (
        <>
            <h1>Статистика магазина</h1>

            {
                stateShop.context.errorMsg.length > 0 &&
                stateShop.context.errorMsg.map((item, index)=>{
                    return <div className="errorMessage" key={index}>
                        {item}
                    </div>
                })
            }

            <section className={clsx("statContainer", styles.chartArea)}>
                <div className={styles.titleBlock}>
                    <h2>График продаж</h2>

                    <div className={styles.chartControls}>
                        <ChartSelect
                            send={sendShop}
                            type={"CHANGE_FILTER"}
                            dataName={"seller"}
                            label={"Продавец: "}
                            name={"seller"}
                            default={stateShop.context.current_seller.toString()}
                            options={stateShop.context.sellers}
                        />
                        <ChartSelect
                            send={sendShop}
                            type={"CHANGE_FILTER"}
                            dataName={"period"}
                            label={"Выбор периода"}
                            name={"periodSelect"}
                            defaultValue={"week"}
                            options={[...periodsList]}
                        />
                    </div>
                </div>
                {
                    stateShop.matches({chart: "loading"})
                        ? <Loader/>
                        : <ChartArea
                            chart_data={stateShop.context.chart_data}
                        />
                }
            </section>

            <section className={"statContainer"}>
                <h2 className="visuallyHidden">Таблица продаж</h2>

                {
                    stateShop.context.list.length > 0
                        ? <>
                            <SellersTable
                                data={stateShop.context.list}
                            />
                            {
                                stateShop.context.pages > 1 &&
                                <Pagination
                                    pages={stateShop.context.pages}
                                    current_page={stateShop.context.current_page}
                                    send={sendShop}
                                />
                            }
                        </>
                        : <NoData/>
                }
            </section>
        </>
    )
}

function SellersTable (props) {
    if (props.data.length === 0) {
        return <NoData/>
    } else {
        return <div className="scrollbox">
            <table id={"distrTable"} className={"statTable"}>
                <thead>
                <tr>
                    <th>Продавцы</th>
                    <th>Продано ед.</th>
                    <th>Сумма продаж</th>
                    <th>Кол-во исп. баллов</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => {
                        return <tr key={item.id}>
                            <td className={styles.itemName}>
                                <Link to={`seller${item.id}`}>
                                    {item.name}
                                </Link>
                            </td>
                            <td className={"noWhiteSpace"}>
                                {item.amount}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.flow)}
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}