import {assign, createMachine} from "xstate";

export const cardMachine = createMachine({
    id: "card",
    initial: "init",
    context: {
        "errorMessage": [],
        "promocode": ""
    },
    states: {
        "init": {
            on: {
                "purchase": {
                    target: "loading"
                },
                "resetting": {
                    actions: ["reset"],
                }
            }
        },
        "loading": {
            invoke: {
                id: "request",
                src: "request",
            },
            on: {
                "done": {
                    target: "show_promocode",
                    actions: ["saveData"]
                },
                "error": {
                    target: "init",
                    actions: ["saveError"]
                }
            }
        },
        "show_promocode": {
            on: {
                "resetting": {
                    actions: ["reset"],
                    target: "init"
                }
            }
        }
    }
}, {
    actions: {
        "saveData": assign({
            "promocode": (ctx, message) => message.data ?? ctx.promocode
        }),
        "reset": assign({
            "errorMessage": [],
            "promocode": ""
        }),
        "saveError": assign({
            "errorMessage": (ctx, message) => message.data ?? ctx.errorMessage,
        })
    },
    services: {
        "request": (ctx, message) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');
                fetch(`/api/shop/purchase/${message.data}`,
                    {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${token}`
                        }
                    }
                )
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 200) {
                            send({type: "done", data: response.promocode})
                        } else {
                            send({type: "error", data: response.message})
                        }
                    })
            }
        }
    }
})