import {Filter} from "../Filter/Filter";
import {Pagination} from "../Pagination/Pagination";
import {useActor, useMachine} from "@xstate/react";
import {shopMachine} from "../../xstate/shop.machine";
import {Loader} from "../Loader/Loader";
import {Showcase} from "../Showcase/Showcase";
import {useEffect} from "react";

export function ShopPage() {

    let [state, send, service] = useMachine(shopMachine);

    return (

        <>
            <Filter
                ShopState={service}
            />
            {
                state.matches("loading")
                ? <Loader/>
                : <>

                    <Showcase
                        ShopState={service}
                    />
                        {
                            state.context.pages>1 &&
                            <Pagination
                                current_page={state.context.current_page}
                                pages={state.context.pages}
                                send={send}
                            />
                        }
                </>
            }
        </>
    )
}