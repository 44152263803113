import styles from "./PrizeCard.module.scss";
import {DialogDisclosure, useDialogState} from "reakit";
import clsx from "clsx";
import {CardModal} from "./CardModal";

export function PrizeCard(props) {

    let dialog = useDialogState({animated: true});

    return (
        <article className={styles.card}>
            <div className={styles.cardInfo}>
                <h3 className={styles.cardName}>
                    {props.name}
                </h3>
                <div className={styles.cardPrice}>
                    {new Intl.NumberFormat('ru-RU').format(props.price)} баллов
                </div>
            </div>
            <div className={styles.cardImage}>
                <img src={props.image} alt={props.name}/>
            </div>
            <DialogDisclosure
                {...dialog}
                className={clsx("btn btnSmall btnContour", styles.purchaseBtn)}
            >
                Купить <span className={"visuallyHidden"}>{props.name}</span>
            </DialogDisclosure>
            <CardModal
                dialog={dialog}
                data={props}
            />
        </article>
    )
}

