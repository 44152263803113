import {useParams} from "react-router-dom";
import {mySalesMachine} from "../../xstate/mysales.machine";
import {useMachine} from "@xstate/react";
import styles from "./SellerStat.module.scss";
import clsx from "clsx";
import {ChartSelect} from "../ChartSelect/ChartSelect";
import {periodsList} from "../../constants";
import {Loader} from "../Loader/Loader";
import {ChartArea} from "../ChartArea/ChartArea";
import {Pagination} from "../Pagination/Pagination";
import {NoData} from "../NoData/NoData";

export function SellerStat() {
    let {sellerID = ""} = useParams();

    let [stateSeller, sendSeller] = useMachine(mySalesMachine, {
        context: {
            ...mySalesMachine.context,
            id: sellerID.replace(/seller/g, "")
        }
    });

    return (
        <>
            <h1>Статистика продавца</h1>

            {
                stateSeller.context.errorMsg.length > 0 &&
                stateSeller.context.errorMsg.map((item, index) => {
                    return <div className="errorMessage" key={index}>
                        {item}
                    </div>
                })
            }

            <section className={clsx("statContainer", styles.chartArea)}>
                <div className={styles.titleBlock}>
                    <h2>График продаж</h2>

                    <div className={styles.chartControls}>
                        <ChartSelect
                            send={sendSeller}
                            type={"CHANGE_FILTER"}
                            dataName={"period"}
                            label={"Выбор периода"}
                            name={"periodSelect"}
                            defaultValue={"week"}
                            options={[...periodsList]}
                        />
                    </div>
                </div>
                {
                    stateSeller.matches({chart: "loading"})
                        ? <Loader/>
                        : <ChartArea
                            chart_data={stateSeller.context.chart_data}
                        />
                }
            </section>

            <section className={"statContainer"}>
                <h2 className="visuallyHidden">Таблица продаж</h2>

                {
                    stateSeller.context.list.length > 0
                        ? <>
                            <SalesTable
                                data={stateSeller.context.list}
                            />
                            {
                                stateSeller.context.pages > 1 &&
                                <Pagination
                                    pages={stateSeller.context.pages}
                                    current_page={stateSeller.context.current_page}
                                    send={sendSeller}
                                />
                            }
                        </>
                        : <NoData/>
                }
            </section>
        </>
    )
}

function SalesTable(props) {
    if (props.data.length === 0) {
        return <NoData/>
    } else {
        return <div className="scrollbox">
            <table id={"distrTable"} className={"statTable"}>
                <thead>
                <tr>
                    <th>Дата и время продажи</th>
                    <th>Товар</th>
                    <th>Кол-во ед.</th>
                    <th>Сумма продаж</th>
                    <th>Баллов получено</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(item => {
                        return <tr key={item.id}>
                            <td>
                                {item.date}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {item.name}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.quantity)}
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.sum)} &#8381;
                            </td>
                            <td className={"noWhiteSpace"}>
                                {new Intl.NumberFormat('ru-RU').format(item.points)}
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}