import {assign, createMachine} from "xstate";

export const distrStatMachine = createMachine({
    id: "owner-stat",
    initial: "loadingShopData",
    type: "parallel",
    context: {
        period: "week", // month, 3months, 6months, year
        chart_data: [],
        shops: [],
        current_shop: 0,
        current_page: 1,
        pages: undefined,
        list: [],
        id: "",
        errorMsg: []
    },
    states: {
        chart: {
            initial: "loading",
            states: {
                "loading": {
                    entry: "scrollTop",
                    invoke: {
                        id: "getChartData",
                        src: "getChartData"
                    },
                    on: {
                        "DONE_CHART": {
                            actions: ["saveChartData"],
                            target: "success"
                        },
                        "ERROR_CHART": {
                            actions: ["saveError"],
                            target: "failure"
                        }
                    }
                },
                "success": {
                    on: {
                        "CHANGE_FILTER": {
                            actions: ["saveChartData"],
                            target: "loading"
                        }
                    }
                },
                "failure": {}
            }
        },
        table: {
            initial: "loading",
            states: {
                "loading": {
                    invoke: {
                        id: "getTableData",
                        src: "getTableData"
                    },
                    on: {
                        "DONE_TABLE": {
                            target: "success",
                            actions: ["saveTableData"]
                        },
                        "ERROR_TABLE": {
                            target: "failure",
                            actions: ["saveError"]
                        }
                    }
                },
                "success": {
                    on: {
                        "prevPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntFirstPage"
                        },
                        "nextPage": {
                            actions: ["changePage"],
                            target: "loading",
                            cond: "isntLastPage"
                        },
                    }
                },
                "failure": {}
            }
        }
    }
}, {
    actions: {
        "saveChartData": assign({
            "period": (ctx, message) => message.data.period ?? ctx.period,
            "chart_data": (ctx, message) => message.data.data ?? ctx.chart_data,
            "shops": (ctx, message)=> message.data.shops ?? ctx.shops,
            "current_shop": (ctx, message)=> message.data.shop ?? ctx.current_shop
        }),
        "saveTableData": assign({
            "current_page": (ctx, message)=> message.data.current_page,
            "pages": (ctx, message)=> message.data.pages,
            "list": (ctx, message)=> {
                return message.data.shops
            },
        }),
        "changePage": assign({
            "current_page": (ctx, message) => {
                return message.type === "nextPage" ? ctx.current_page + 1 : ctx.current_page - 1
            }
        }),
        "scrollTop": () => {
            window.scrollTo(0, 0);
        },
        "saveError": assign({
            "errorMsg": (ctx, message)=> message.data.message
        })
    },
    services: {
        "getChartData": (ctx) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                let url = new URL(`/api/personal/shops/chart/${ctx.id}`, window.location.href);
                url.searchParams.set("period", ctx.period);

                fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(response => {

                        if (response.code === 200) {
                            send({
                                type: "DONE_CHART",
                                data: response
                            })
                        }
                        else{
                            send({
                                type: "ERROR_CHART",
                                data: response
                            })

                        }
                    })
            }
        },
        "getTableData": (ctx) => {
            return function (send) {
                let token = localStorage.getItem('sessionID');

                let url = new URL(`/api/personal/shops/${ctx.id}`, window.location.href);
                url.searchParams.set("page", ctx.current_page);

                fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.code === 200) {send({
                            type: "DONE_TABLE",
                            data: response
                        })

                        } else {
                            send({
                                type: "ERROR_TABLE",
                                data: response
                            })

                        }
                    })
            }
        }
    },
    "guards": {
        "isntFirstPage": (ctx, message) => ctx.current_page !== 1,
        "isntLastPage": (ctx, message) => ctx.current_page !== ctx.pages,
    }
});