import {useState} from "react";
import {VictoryArea, VictoryChart, VictoryCursorContainer, VictoryZoomContainer} from "victory";
import {NoData} from "../NoData/NoData";

export function ChartArea(props) {

    let quantity = [];
    props.chart_data.map((item) => {
        quantity.push(item.quantity)
    });
    let maxDom = Math.max(...quantity) + 2;

    return (
        props.chart_data.length > 0
            ? <div className="scrollbox scrollbox--rounded">
                <VictoryChart
                    height={400}
                    width={1256}
                    maxDomain={{y: maxDom}}
                    containerComponent={
                        <VictoryZoomContainer responsive={false}
                                              zoomDimension="x"
                        />
                    }
                >
                    <VictoryArea
                        padding={{left: 20}}
                        style={
                            {
                                data: {
                                    stroke: "#D02239",
                                    strokeWidth: 4,
                                    fill: "rgba(208, 34, 57, 0.15)"
                                }
                            }
                        }
                        x={"date"}
                        y={"quantity"}
                        interpolation={"natural"}
                        data={props.chart_data}
                        containerComponent={
                            <VictoryCursorContainer
                                cursorDimension="x"
                                cursorLabel={(point) => `${point.y} ед.`}
                            />
                        }
                        scale={{x: "time"}}
                    />

                </VictoryChart>
            </div>

            : <NoData/>
    )
}