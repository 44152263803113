import styles from "./UploadReceip.module.scss";
import {useEffect, useState} from "react";
import {Popover, PopoverDisclosure, usePopoverState} from "reakit";
import {Icon} from "../SVGsprite/svgsprite";
import {useMachine} from "@xstate/react";
import {uploadReceiptMachine} from "../../xstate/upload.machine";
import {Loader} from "../Loader/Loader";

export function UploadReceipt() {
    let [state, send] = useMachine(uploadReceiptMachine);

    /* Предпросмотр загружаемого изображения */
    let [receiptPreview, setReceiptPreview] = useState("");

    /* Поповеры */
    let popoverDate = usePopoverState({placement: "auto"});
    let popoverTime = usePopoverState({placement: "left"});
    let popoverFD = usePopoverState({placement: "left"});
    let popoverFN = usePopoverState({placement: "left"});
    let popoverFP = usePopoverState({placement: "left"});
    let popoverSum = usePopoverState({placement: "left"});

    /* Обработка изменений файлового инпута */
    function handleFileChange(event) {
        let file = event.target.files[0];
        setReceiptPreview(URL.createObjectURL(file));
    }

    function submitForm(event) {
        event.preventDefault();
        if (state.matches({type: "photo"})) {
            send("SUBMIT_PHOTO");
        } else {
            send("SUBMIT_FIELDS");
        }

    }

    useEffect(() => {
        document.title = "Загрузить чек | Программа лояльности SK ZIC";
    }, []);

    return (
        <form
            className={styles.uploadingForm}
            onSubmit={(event) => submitForm(event)}
        >

            {
                state.matches({form: "sending"}) &&
                <div className={styles.loading}><Loader/></div>
            }

            <h1>Загрузить чек</h1>

            {
                state.matches({form: "failure"}) && <>
                    {
                        state.context.errorMsg?.map((item, index)=>{
                            return <div className={"errorMessage"} key={index}>
                                {item}
                            </div>
                        })
                    }
                </>
            }

            {
                state.matches({form: "success"}) &&
                <div className={styles.successMessage}>
                    <h2>Чек успешно загружен</h2>
                    <button
                        type="button"
                        className={"btn btnContour"}
                        onClick={()=>window.location.reload()}
                    >
                        Загрузить еще один чек
                    </button>
                </div>
            }
            {
                state.matches({type: "fields"}) &&
                <>
                    <div className="formRow">
                        <label htmlFor="date">
                            Дата
                        </label>
                        <div className="inputWrapper">
                            <input
                                id={"date"}
                                name={"date"}
                                type="text"
                                inputMode="numeric"
                                onChange={(event) => {
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "date": event.target.value
                                        }
                                    })
                                }}
                            />
                            <PopoverDisclosure {...popoverDate}>
                        <span className="visuallyHidden">
                            Открыть подсказку
                        </span>
                                <Icon
                                    name={"icon-question"}
                                    sizeW="24"
                                    sizeH="24"
                                    sizeWV="32"
                                    sizeHV="32"
                                />
                            </PopoverDisclosure>
                            <Popover className={"popover popover--image"} {...popoverDate}
                                     aria-label="Где находится дата документа">
                                <img src="/images/receipt-date.jpg" alt=""/>
                            </Popover>
                        </div>

                        {
                            state.context.dateError &&
                           <div className={"errorMessage"} aria-live={"polite"}>
                               <span className={"visuallyHidden"}>Ошибка в поле дата:</span>
                               {state.context.dateError}
                           </div>
                        }
                    </div>


                    <div className="formRow">
                        <label htmlFor="time">
                            Время
                        </label>
                        <div className="inputWrapper">
                            <input
                                name={"time"}
                                id={"time"}
                                type="text"
                                inputMode="numeric"
                                onChange={(event) => {
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "time": event.target.value
                                        }
                                    })
                                }}
                            />
                            <PopoverDisclosure {...popoverTime}>
                                <span className="visuallyHidden">
                                    Открыть подсказку
                                </span>
                                <Icon
                                    name={"icon-question"}
                                    sizeW="24"
                                    sizeH="24"
                                    sizeWV="32"
                                    sizeHV="32"
                                />
                            </PopoverDisclosure>
                            <Popover className={"popover popover--image"} {...popoverTime}
                                     aria-label="Где находится время документа">
                                <img src="/images/receipt-time.jpg" alt=""/>
                            </Popover>
                        </div>
                        {
                            state.context.timeError &&
                            <div className={"errorMessage"} aria-live={"polite"}>
                                <span className={"visuallyHidden"}>Ошибка в поле время:</span>
                                {state.context.timeError}
                            </div>
                        }
                    </div>
                </>
            }

            {
                state.matches({type: "photo"}) &&
                <div className="formRow">
                    <div className={styles.receiptImage}>
                        <input
                            name={"image"}
                            id={"image"}
                            type="file"
                            accept="image/*"
                            onChange={
                                (event) => {
                                    handleFileChange(event)
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "image": event.target.files[0]
                                        }
                                    })
                                }
                            }
                        />
                        {receiptPreview.length > 0 && <img src={receiptPreview} alt=""/>}
                    </div>
                    <label htmlFor="image">Загрузить фото чека</label>

                    {
                        state.context.imageError &&
                        <div className={"errorMessage"} aria-live={"polite"}>
                            <span className={"visuallyHidden"}>Ошибка в поле добавления изображения:</span>
                            {state.context.imageError}
                        </div>
                    }
                </div>
            }

            <div className="formRow">
                <label htmlFor="fd">ФД</label>
                <div className="inputWrapper">
                    <input
                        name={"fd"}
                        id={"fd"}
                        type="text"
                        inputMode="numeric"
                        onChange={(event) => {
                            send({
                                type: "INPUT",
                                data: {
                                    "fd": event.target.value
                                }
                            })
                        }}
                    />
                    <PopoverDisclosure {...popoverFD}>
                        <span className="visuallyHidden">
                            Открыть подсказку
                        </span>
                        <Icon
                            name={"icon-question"}
                            sizeW="24"
                            sizeH="24"
                            sizeWV="32"
                            sizeHV="32"
                        />
                    </PopoverDisclosure>
                    <Popover className={"popover popover--image"} {...popoverFD} aria-label="Где находится ФД">
                        <img src="/images/receipt-fd.jpg" alt=""/>
                    </Popover>
                </div>

                {
                    state.context.fdError &&
                    <div className={"errorMessage"} aria-live={"polite"}>
                        <span className={"visuallyHidden"}>Ошибка в поле Номер Фискального Документа:</span>
                        {state.context.fdError}
                    </div>
                }
            </div>

            {
                state.matches({type: "fields"}) &&
                <>
                    <div className="formRow">
                        <label htmlFor="fn">ФН</label>
                        <div className="inputWrapper">
                            <input
                                name={"fn"}
                                id={"fn"}
                                type="text"
                                inputMode="numeric"
                                onChange={(event) => {
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "fn": event.target.value
                                        }
                                    })
                                }}
                            />
                            <PopoverDisclosure {...popoverFN}>
                                <span className="visuallyHidden">
                                    Открыть подсказку
                                </span>
                                <Icon
                                    name={"icon-question"}
                                    sizeW="24"
                                    sizeH="24"
                                    sizeWV="32"
                                    sizeHV="32"
                                />
                            </PopoverDisclosure>
                            <Popover className={"popover popover--image"} {...popoverFN}
                                     aria-label="Где находится ФН">
                                <img src="/images/receipt-time.jpg" alt=""/>
                            </Popover>
                        </div>

                        {
                            state.context.fnError &&
                            <div className={"errorMessage"} aria-live={"polite"}>
                                <span className={"visuallyHidden"}>Ошибка в поле Номер фискального накопителя:</span>
                                {state.context.fnError}
                            </div>
                        }
                    </div>

                    <div className="formRow">
                        <label htmlFor="fp">ФП</label>
                        <div className="inputWrapper">
                            <input
                                name={"fp"}
                                id={"fp"}
                                type="text"
                                inputMode="numeric"
                                onChange={(event) => {
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "fp": event.target.value
                                        }
                                    })
                                }}
                            />
                            <PopoverDisclosure {...popoverFP}>
                        <span className="visuallyHidden">
                            Открыть подсказку
                        </span>
                                <Icon
                                    name={"icon-question"}
                                    sizeW="24"
                                    sizeH="24"
                                    sizeWV="32"
                                    sizeHV="32"
                                />
                            </PopoverDisclosure>
                            <Popover className={"popover popover--image"} {...popoverFP}
                                     aria-label="Где находится ФП">
                                <img src="/images/receipt-fp.jpg" alt=""/>
                            </Popover>
                        </div>

                        {
                            state.context.fpError &&
                            <div className={"errorMessage"} aria-live={"polite"}>
                                <span className={"visuallyHidden"}>Ошибка в поле Фискальный признак документа:</span>
                                {state.context.fpError}
                            </div>
                        }
                    </div>

                    <div className="formRow">
                        <label htmlFor="sum">Сумма чека</label>
                        <div className="inputWrapper">
                            <input
                                name={"sum"}
                                id={"sum"}
                                type="text"
                                inputMode="numeric"
                                onChange={(event) => {
                                    send({
                                        type: "INPUT",
                                        data: {
                                            "sum": event.target.value
                                        }
                                    })
                                }}
                            />
                            <PopoverDisclosure {...popoverSum}>
                        <span className="visuallyHidden">
                            Открыть подсказку
                        </span>
                                <Icon
                                    name={"icon-question"}
                                    sizeW="24"
                                    sizeH="24"
                                    sizeWV="32"
                                    sizeHV="32"
                                />
                            </PopoverDisclosure>
                            <Popover className={"popover popover--image"} {...popoverSum}
                                     aria-label="Где находится сумма чека">
                                <img src="/images/receipt-sum.jpg" alt=""/>
                            </Popover>
                        </div>

                        {
                            state.context.sumError &&
                            <div className={"errorMessage"} aria-live={"polite"}>
                                <span className={"visuallyHidden"}>Ошибка в поле сумма чека:</span>
                                {state.context.sumError}
                            </div>
                        }
                    </div>
                </>
            }

            <div className="formRow">
                <button type="submit" className={"btn btnPrimary"}>
                    Загрузить чек
                </button>
            </div>

            <div className="formRow">
                <button
                    type="button"
                    className={"btn btnSmall btnGhost"}
                    onClick={
                        () => {
                            send({type: "TOGGLE"})
                            setReceiptPreview("")
                        }
                    }
                >
                    {
                        state.matches({type: "photo"}) ? "Ввести вручную" : "Загрузить фото"
                    }
                </button>
            </div>
        </form>
    )
}